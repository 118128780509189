import { useLocation } from "@gatsbyjs/reach-router";
import { APAsyncButton, APCard, APCenter, APColumn, APExpanded, APFormFieldDropdown, APFormFieldText, APImage, APInkWell, APPadding, APPalette, APRow, APSizedBox, APTag, APText, CloseIcon, Deferred, InfoCircleIcon, InfoIcon, REGEX, getIt, showErrorDialog, useAPForm } from "ap-components";
import { useEffect, useState } from "react";
import { Constants, DefaultObjectType } from "./ClientActivation";
import { APTypography } from "ap-components/src/utils/APTypography";
import moment from "moment";
import Activation2Model, { TaxStatus, HoldingType } from "../../Models/Activation2Model";
import { ValidatePANDetailsRequestType } from "../../Services/ClientApiServices";
import MutualFundApiServices, { BasicDetailsReqClass } from "../../Services/MutualFundServices";
import { Drawer, Button } from '@mui/material';
import { launchPage } from "../../Common/Utils";


export default function ClientActivationBasicDetailsPage({ updateActivationData }: { updateActivationData: (activationData: Partial<Activation2Model>) => void }) {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [activationData, setActivationData] = useState<Activation2Model>();
    const [form, setForm] = useState<Partial<Activation2Model>>();

    const [successDrawerOpen, setSuccessDrawerOpen] = useState(false);
    const [errorDrawerOpen, setErrorDrawerOpen] = useState(false);
    const [panValidationResponse, setPanValidationResponse] = useState<{ firstHolderName: string; secondHolderName: string; }>();
    const [panValidationErrorResponse, setPanValidationErrorResponse] = useState<{ errorTitle: string, errorDiscription: string }>();
    const [isCurrentPanInvalid, setIsCurrentPanInvalid] = useState(false);

    useEffect(() => {
    }, [form])

    if (!token) return (<APCenter><APTag variant="negative">Invalid user</APTag></APCenter>);

    useEffect(() => { getActivationDetails() }, []);

    async function getActivationDetails() {
        if (!form || !form.panNumber) {
            const activationData = await getIt(MutualFundApiServices).getActivationDetails(token ?? "");

            setForm(activationData)
            setActivationData(activationData);
        }
    }

    if (!form) {
        return <APCenter><APTag variant='default'>Please Wait</APTag></APCenter>
    };

    const { holdingType, taxStatus } = form;




    return (
        <APPadding noClone>
            <APColumn>
                <APSizedBox>
                    <APCard style={{ maxWidth: "450px" }}>
                        <APColumn
                            style={{ color: APPalette["grey-500"] }}
                            crossAxisAlignment="stretch"
                            gap="8px"
                        >
                            <APText style={APTypography.h2}>Hi {form?.name}</APText>
                            <APText style={APTypography["paragraph-small"]}>
                                Let's get your account investment ready by completing one time
                                activation. It will take just a few minutes.
                            </APText>
                            <APExpanded>
                                <APFormFieldDropdown
                                    initialValue={form.taxStatus}
                                    items={Constants.taxStatus}
                                    key={form.taxStatus}
                                    label="Tax Status"
                                    onChanged={(v) => {
                                        if (v) {
                                            const propsToSet: DefaultObjectType = {
                                                taxStatus: v as TaxStatus
                                            };

                                            if (v == "02") {
                                                propsToSet.holdingType = "SI";
                                            }

                                            setForm((prevForm) => ({
                                                ...prevForm,
                                                ...propsToSet
                                            }));
                                        }
                                    }}
                                    validator={(v) => {
                                        if (!v) {
                                            return "Tax Status Required";
                                        }
                                        return null;
                                    }}
                                    style={{ height: "40px" }}
                                />
                            </APExpanded>
                            {activationData?.holdingType === "AS" && (
                                <APExpanded>
                                    <APFormFieldDropdown
                                        initialValue={form.holdingType}
                                        items={Constants.holdingType}
                                        key={form.holdingType}
                                        label="Holding Type"
                                        disabled={form.taxStatus == "02"}
                                        onChanged={(v) => {
                                            if (v) {
                                                setForm((prevForm) => ({
                                                    ...prevForm,
                                                    holdingType: v as HoldingType
                                                }));
                                            }
                                        }}
                                        validator={(v) => {
                                            if (!v) {
                                                return "Holding Type Required";
                                            }
                                            return null;
                                        }}
                                        style={{ height: "40px" }}
                                    />
                                </APExpanded>
                            )}
                            {holdingType === "AS" && taxStatus != "02" && (
                                <APText style={APTypography.h4}>Fill details for Holder 1</APText>
                            )}
                            <APExpanded>
                                <APFormFieldText
                                    key={form?.panNumber}
                                    label={`${form.taxStatus == "02" ? "Guardian " : ""}PAN Number`}
                                    textAppearance="uppercase"
                                    initialValue={form.panNumber}
                                    validator={(v) => {
                                        if (!REGEX.PAN.test(v)) {
                                            return "Invalid PAN Number";
                                        }
                                        if (isCurrentPanInvalid) {
                                            return "Invalid PAN Number"
                                        }
                                    }}
                                    onChanged={(v) => {
                                        form.panNumber = v.toUpperCase();
                                    }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldText
                                    initialValue={form.dob}
                                    label={`${form.taxStatus == "02" ? "Guardian " : ""}Date of Birth`}
                                    keyboardType="date"
                                    onChanged={(v) => {
                                        if (v) {
                                            if (isCurrentPanInvalid) {
                                                setIsCurrentPanInvalid(false);
                                            }
                                            const formattedDate = moment(v).format("DD/MM/YYYY");
                                            form.dob = formattedDate;
                                        }
                                    }}
                                    validator={(v) => {
                                        if (!v) return "D.O.B. is required";
                                        if (v && moment().diff(v, "years") < 1)
                                            return " Invalid Date of Birth";
                                        if (!moment(v).isValid()) return "Invalid Date";
                                        return null;
                                    }}
                                />
                            </APExpanded>
                            {holdingType === "AS" && taxStatus != "02" && (
                                <>
                                    <APText style={APTypography.h4}>
                                        Fill details for Holder 2
                                    </APText>
                                    <APExpanded>
                                        <APFormFieldText
                                            label={`${form.taxStatus == "02" ? "Guardian " : ""
                                                }PAN Number`}
                                            textAppearance="uppercase"
                                            validator={(v) => {
                                                if (!REGEX.PAN.test(v)) {
                                                    return "Invalid PAN Number";
                                                }
                                            }}
                                            onChanged={(v) => {
                                                form.secondHolderPAN = v.toUpperCase();
                                            }}
                                        />
                                    </APExpanded>
                                    <APExpanded>
                                        <APFormFieldText
                                            initialValue={form.secondHolderDOB}
                                            label={`${form.taxStatus == "02" ? "Guardian " : ""
                                                }Date of Birth`}
                                            keyboardType="date"
                                            onChanged={(v) => {
                                                if (v) {
                                                    form.secondHolderDOB = moment(v).format("DD/MM/YYYY");
                                                }
                                            }}
                                            validator={(v) => {
                                                if (!v) return "D.O.B. is required";
                                                if (v && moment().diff(v, "years") < 1)
                                                    return " Invalid Date of Birth";
                                                if (!moment(v).isValid()) return "Invalid Date";
                                                return null;
                                            }}
                                        />
                                    </APExpanded>
                                </>
                            )}
                            <APAsyncButton
                                variant="contained"
                                onClick={async () => {
                                    const basicDetails: ValidatePANDetailsRequestType = {
                                        token: token!,
                                        firstHolderPanDetails: {
                                            panNumber: form.panNumber ?? "",
                                            dob: form.dob ?? ''
                                        }
                                    };


                                    if (holdingType === "AS") {
                                        basicDetails.secondHolderPanDetails = {
                                            panNumber: form.secondHolderPAN!,
                                            dob: form.secondHolderDOB!
                                        };
                                    }

                                    try {
                                        const panValidationResponse = await getIt(MutualFundApiServices).validatePanDetails(basicDetails);
                                        setPanValidationResponse(panValidationResponse);
                                        setSuccessDrawerOpen(true);
                                        return;
                                    } catch (error: any) {
                                        const { metaData } = error;
                                        if (error.message == "PAN validation error") {
                                            setPanValidationErrorResponse(metaData);
                                            setErrorDrawerOpen(true);
                                        }
                                        return;
                                    }
                                }}
                            >
                                Next
                            </APAsyncButton>
                        </APColumn>
                    </APCard>
                </APSizedBox>
                <Drawer
                    anchor="bottom"
                    open={successDrawerOpen}
                    onClose={() => setSuccessDrawerOpen(false)}
                >
                    {panValidationResponse && (
                        <PanConfirmationContent
                            deferred={new Deferred<void>()}
                            panValidationResponse={panValidationResponse}
                            activationData={form!}
                            submitFunction={async (deferred: Deferred<void>) => {
                                try {
                                    if (!form) {
                                        throw new Error("Try Again");
                                    };

                                    const reqData: BasicDetailsReqClass = {
                                        token: token!,
                                        taxStatus: form.taxStatus!,
                                        holdingType: form.holdingType!,
                                        firstHolderPanDetails: {
                                            panNumber: form.panNumber!,
                                            dob: form.dob!
                                        },
                                    };

                                    if (form.holdingType === 'AS') {
                                        reqData.secondHolderPanDetails = {
                                            panNumber: form.secondHolderPAN ?? "",
                                            dob: form.secondHolderDOB ?? ""
                                        }
                                    };
                                    const data = await getIt(MutualFundApiServices).validateKycStatusAndSaveBasicDetails(reqData);
                                    updateActivationData(data);
                                    deferred.resolve();
                                } catch (error: any) {
                                    showErrorDialog(error.message);
                                }
                            }}
                            closeDrawer={() => setSuccessDrawerOpen(false)}
                        />
                    )}
                </Drawer>
                <Drawer
                    anchor="bottom"
                    open={errorDrawerOpen}
                    onClose={() => setErrorDrawerOpen(false)}
                >
                    {panValidationErrorResponse && (
                        <PANCheckErrorContent
                            closeDrawer={() => setErrorDrawerOpen(false)}
                            setPanError={() => setIsCurrentPanInvalid(true)}
                            panValidationErrorResponse={panValidationErrorResponse} />
                    )}
                </Drawer>
            </APColumn>
        </APPadding>
    );
}

function PanConfirmationContent({ deferred, panValidationResponse, submitFunction, closeDrawer, activationData }: { deferred: Deferred<void>; panValidationResponse: { firstHolderName: string; secondHolderName: string }; submitFunction: (deferred: Deferred<void>) => {}; closeDrawer: () => void; activationData: Partial<Activation2Model> }) {
    return (
        <APPadding padding="16px">
            <APColumn crossAxisAlignment="stretch" gap="16px">
                <APRow mainAxisAlignment="spaceBetween">
                    <APText style={APTypography.h3}>PAN matches DOB</APText>
                    <APInkWell onClick={() => closeDrawer()}>
                        <CloseIcon />
                    </APInkWell>
                </APRow>
                <APCenter>
                    <APImage width={155} height={155} src="/client-pan-verified.svg" />
                </APCenter>
                {activationData.taxStatus !== '02' && <>
                    {!panValidationResponse.secondHolderName && (
                        <APText style={APTypography["paragraph-small"]}>
                            Investments will be made under the following name as per the PAN
                            entered:{" "}
                            <APText style={APTypography.h3}>
                                {!panValidationResponse.secondHolderName
                                    ? panValidationResponse.firstHolderName
                                    : ""}
                            </APText>
                        </APText>
                    )}
                    {!!panValidationResponse.secondHolderName && (
                        <APColumn crossAxisAlignment="start">
                            <APText style={APTypography.h3}>
                                &#8226; {panValidationResponse.firstHolderName}
                            </APText>
                            <APText style={APTypography.h3}>
                                &#8226; {panValidationResponse.secondHolderName}
                            </APText>
                            <APSizedBox height="16px" />
                        </APColumn>
                    )}
                </>}
                {activationData.taxStatus === '02' && <>
                    <APText style={APTypography["paragraph-small"]}>
                        Guardian name as per PAN entered: {" "}
                        <APText style={APTypography.h3}>
                            {panValidationResponse.firstHolderName}
                        </APText>
                    </APText>
                </>}
                <APAsyncButton onClick={async () => submitFunction(deferred)}>
                    Confirm & Continue
                </APAsyncButton>
            </APColumn>
        </APPadding >
    );
}

function PANCheckErrorContent({ panValidationErrorResponse, setPanError, closeDrawer }: { panValidationErrorResponse?: { errorTitle: string, errorDiscription: string }; closeDrawer: () => void; setPanError: () => void; }) {
    if (!panValidationErrorResponse) return <></>;

    return (
        <APPadding padding="16px">
            <APColumn crossAxisAlignment="stretch" gap="16px">
                <APRow mainAxisAlignment="spaceBetween">
                    <APRow gap="8px">
                        <InfoIcon size={20} color={APPalette["negative-300"]} />
                        <APText style={APTypography.h3}>{panValidationErrorResponse.errorTitle}</APText>
                    </APRow>
                    <APInkWell onClick={() => closeDrawer()}>
                        <CloseIcon />
                    </APInkWell>
                </APRow>
                <APText style={APTypography["paragraph-small"]}>{panValidationErrorResponse.errorDiscription}</APText>
                <APRow mainAxisAlignment="spaceBetween">
                    <APExpanded>
                        <APAsyncButton type="secondary" onClick={async () => closeDrawer()}>
                            Close
                        </APAsyncButton>
                    </APExpanded>
                    <APSizedBox width="16px" />
                    {panValidationErrorResponse.errorTitle != 'PAN is not linked to Aadhaar' && <APExpanded>
                        <APAsyncButton onClick={async () => closeDrawer()}>
                            Try Again
                        </APAsyncButton>
                    </APExpanded>}
                    {panValidationErrorResponse.errorTitle == 'PAN is not linked to Aadhaar' &&
                        <APExpanded>
                            <APAsyncButton onClick={async () => launchPage("https://www.incometax.gov.in/iec/foportal/help/how-to-link-aadhaar")}>
                                Try Again
                            </APAsyncButton>
                        </APExpanded>}
                </APRow>
            </APColumn>
        </APPadding>
    );
}
